import React, {useState} from 'react'


const Footer = ()=>{
    
    return   <footer>
						<div id="copyright">
							<div className="container">
								<div className="row">
									<div className="col-md-12">
										<div className="site-info text-center">
										<p>Investing in securities involves risks, and there is always the potential of losing money when you invest in securities. You should review any planned Finance transactions that may have tax or legal implications with your personal tax or legal advisor.

Securities products are provided by Merrill Lynch, Pierce, Fenner & Smith Incorporated (also referred to as "MLPF&S", or "Merrill"), a registered broker-dealer, Member SIPC layer, and a wholly-owned subsidiary of Equity Finance Bank Corporation ("BofA Corp."). MLPF&S makes available certain investment products sponsored, managed, distributed or provided by companies that are affiliates of BofA Corp.

Equity Finance Bank Private Bank operates through Equity Finance Bank, N.A., and other subsidiaries of BofA Corp. Trust and fiduciary services are provided by Equity Finance Bank Private Bank, a division of Equity Finance Bank, N.A., Member FDIC, and a wholly-owned subsidiary of BofA Corp.

Insurance Products are offered through Merrill Lynch Life Agency Inc. (MLLA), Equity Finance Bank, N.A. and/or Equity Finance Bank Insurance Services, Inc., all of which are licensed insurance agencies and wholly-owned subsidiaries of Equity Finance Bank Corporation.

Banking, credit card, automobile loans, mortgage and home equity products are provided by Equity Finance Bank, N.A. and affiliated banks, Members FDIC and wholly owned subsidiaries of Equity Finance Bank Corporation. Credit and collateral are subject to approval. Terms and conditions apply. This is not a commitment to lend. Programs, rates, terms and conditions are subject to change without notice.
</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						
						<div id="google_translate_element"></div>
						</footer>
}
export default React.memo(Footer)