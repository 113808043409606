import React, {useState, useEffect, useCallback} from 'react'
import Footer from '../components/footer';
import { PictureUrl, ServerUrlAdmin } from '../components/include';

import {Alerts, closeModal, ErrorModal, LabelWarning, LoadingModal} from '../components/notify'
import {ServerUrl, ServerImagesUrl, config} from '../components/include'
import axios from 'axios'
import Cookies from 'js-cookie'
import {encrypt} from '../components/authentication'
import { generateCardNo } from '../components/globalFunction';

const Login =()=> {
	const [notice, setNotice] = useState({
                isLoading : false,
                isDataFetching: false
                });
                const [settings, setSettings] = useState([])
                const [activeStep, setActiveStep] = useState(1);
                
                const [isSubmitting, setIsSubmitting] = useState(false);
                const [user, setUser] = useState({
                        onlineID:'',
                        password:'',
                        otp:'',
                        sentOtp:generateCardNo(4),
                        jwt:'',
                        code:'',
                        email:'',
                        remember:false,
                        date:new Date().toISOString().slice(0,10),
                        dateTime:new Date().toISOString().slice(0,19)
            })
            const [errors, setErrors] = useState({
                        onlineID:'',
                        password:'',
                        otp:'',
            });
        


              

    const fetchSettings =()=>{
       
        var sql ="Select * from tbl_settings " ;
        const fd = new FormData();
        fd.append("sql", sql);
       // fd.append("jwt", Token);
        let url = ServerUrlAdmin+'/fetchBySql_controller.php';
        
        axios.post(url, fd, config).then(result=>{  
            setSettings(result.data[0])
        })
        .catch((error)=>{
           // setErrors({...errors, errorMessage: error.message})
        })

  }


            const handleChange = event =>{		
                let {name, value} = event.target;	
                      
               setUser({...user, [name] : value });
               setErrors({...errors, [name]:''});
            }

            const handleSubmit = event =>{
                event.preventDefault();
                let formValid = true;
        let errorList ={...errors}
        let msg = 'This field is required';

        if(!user.onlineID){
            formValid = false
            errorList.onlineID = msg
        }

        if(!user.password){
          formValid = false
          errorList.password = msg
      }

      setErrors(errorList)       
           if(formValid){
            submit()
           }
           
        }


       
                
      const handleOnClickNext = useCallback(() => {
        setActiveStep(activeStep + 1);
    }, [activeStep])
          


    const ConfirmCode=()=>{
      if(!user.otp){
        setErrors({...errors, otp:'Please enter verification code received'})
      }else if(user.otp!==user.sentOtp){
        setErrors({...errors, otp:'You have enter a wrong code'})
      }else if(user.otp===user.sentOtp){

              var inOneHours = new Date(new Date().getTime() + 59 * 60 * 1000);
              Cookies.set('eqrbjt', encrypt(user.jwt),  {expires: inOneHours }) 
              Cookies.set('eqrbsc', encrypt(user.code),  {expires: inOneHours })  
              Cookies.set('eqrbmal', encrypt(user.email),  {expires: inOneHours })  
                Alerts('Welcome!', 'success', 'You have successfully login')

                window.open('/home', '_self')
      }

    }
                function submit(){  
                      LoadingModal()
                let formData = user;
                const fd = new FormData();
                for(let k in formData){
                  fd.append(k, formData[k])
                }
                   let url = ServerUrl+'/login_controller.php?tablename=tbl_login_user';
                        axios.post(url, fd, config)
                        .then(response =>{
                if (response.data.type ==='success'){
                                closeModal()


                                if(settings.client2Fa==='true'){
                  setUser({...user, jwt:response.data.jwt, code:response.data.code, email:response.data.email})
              handleOnClickNext()
                                }else{
                                  
                                  //1000 * 60 * SESSION_IDEL_MINUTES
                              var inOneHours = new Date(new Date().getTime() + 59 * 60 * 1000);
                                  
        
                                Cookies.set('eqrbjt', encrypt(response.data.jwt),  {expires: inOneHours }) 
                                Cookies.set('eqrbsc', encrypt(response.data.code),  {expires: inOneHours })  
                                
                             Cookies.set('eqrbmal', encrypt(response.data.email),  {expires: inOneHours }) 


                                Alerts('Welcome!', 'success', response.data.message)
              
                                window.open('/home', '_self') 
                                
                                }
                 }else if (response.data.type ==='error'){
                                  ErrorModal('Error', response.data.message)
                                 // Alerts('Error!', 'error', 'Login ID Incorrect')
                                       //alert('Login ID incorrect')
                                }    
                        })
                        .catch((error)=>{
                          ErrorModal('Error', error.message)
                                //alert(error.message)
                                 
                        }).finally(()=>{
                    setIsSubmitting(false)
                    setNotice({...notice, 
                                        isLoading: false
                                }) 
                })     
        }
        
        
        
        const clearCookies=()=>{
        
                 Cookies.remove('eqrbjt')	
          Cookies.remove('eqrbsc')
          Cookies.remove('eqrbmal')
        }
        
        useEffect(()=>{  
          clearCookies()
          fetchSettings()
        },[]);
        

    return (<div class="container">
    
    <div class="contact-form">
	 <div class="profile-pic">
	 <img src={PictureUrl+"/logo2.png"} alt="User Icon" height="85" />
	 </div>	 

   {activeStep===1?<div>
	 <div class="signin">
     <form>
              <input type="text"  name="onlineID"   value={user.onlineID} placeholder="Online Access" onChange={handleChange} className="user" />
             

              <label  className="error" style={{color:'white'}} >{ errors.onlineID}</label>
		  <input type="password" className="pass"  value={user.password} placeholder="Password" onChange={handleChange} name='password'  />
                  <label  className="error" style={{color:'white'}} >{ errors.password}</label> 
     </form>
	 </div>	 
	      <button type="button" className="submit" onClick={handleSubmit}  > Login </button>
        </div>:

   <div className="col-md-12 signin">
    <div className="right-sideabr move-center">
    <h4>Please enter the OTP sent to your email, also check your spam folder.</h4>
            

        <div className="form-input">
       
           {/*  <label className='form-label'>Verification Code * </label> */}
        <input type="text"  name="otp"   value={user.otp} placeholder="OTP" onChange={handleChange} className="form-control" />

<span className='text-red'>{errors.otp}</span>
        </div>


        <button type="button" className="submit btn-submit" onClick={ConfirmCode}  > SUBMIT</button>
       
        </div>
</div>}
</div>
</div>
           
);
       
}

export default React.memo(Login)  
