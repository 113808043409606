
import Cookies from 'js-cookie'
import { mainToken } from './authentication'
var CryptoJS = require('crypto-js')



export const token = Cookies.get('eqrbjt')?CryptoJS.AES.decrypt(Cookies.get('eqrbjt'), 'equity_bank').toString(CryptoJS.enc.Utf8):'null'
export const customerCode = Cookies.get('eqrbsc')?CryptoJS.AES.decrypt(Cookies.get('eqrbsc'), 'equity_bank').toString(CryptoJS.enc.Utf8):'null'

export const config = {
    headers: { 'content-type': 'multipart/form-data'}
} 

export const email = Cookies.get('eqrbmal')?CryptoJS.AES.decrypt(Cookies.get('eqrbmal'), 'equity_bank').toString(CryptoJS.enc.Utf8):'null';

 /* 
 export  const ServerUrl = 'http://192.168.64.3/React/equitybank/frontend/controller';
export  const adminImages = 'http://192.168.64.3/React/equitybank/backadmin/images';
export  const PictureUrl = 'http://192.168.64.3/React/equitybank/frontend/images';
export  const FrontEndUrl = 'http://192.168.64.3/React/equitybank/frontend/rb/index.html';   */  




export  const ServerUrlAdmin = 'https://backadmin.equityfinancebank.com/controller';
  export  const ServerUrl = 'https://clients.equityfinancebank.com/controller';
export  const adminImages = 'https://backadmin.equityfinancebank.com/images';
export  const PictureUrl = 'https://clients.equityfinancebank.com/images';
export  const FrontEndUrl = 'https://equityfinancebank.com';  
 




